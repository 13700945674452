import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
// styles
import clsx from 'clsx';
import styles from './areabutler.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import DemoVideo from '../components/demo-video';
import Layout from '../components/item/layout';
import Blockquote from '../components/blockquote';
import Technology from 'components/technology';
import Team from '../components/team';
//assets
import workingProcess from './working-process.json';
//types
import { Technologies } from 'components/Types';
// other
import { meta } from '../../../metaData';

const technologies = [
  'New pricing model development;',
  'Map style improvements;',
  'Serve-side operations;',
  'Integration with PayPal;',
  'API import;',
  'Real estate sorting and filtering implementation;',
  'Implemented algorithms for filtering points of interest (POI);',
  'Support and bug fixing.',
];
const technologyStack: { field: string; list: readonly Technologies[] }[] = [
  {
    field: 'Frontend',
    list: ['react'],
  },
  {
    field: 'Backend',
    list: ['nest', 'mongo'],
  },
];
const onboarding = [
  'Onboarding organization. We ensure a smooth and efficient onboarding process for the employee.',
  'Employee support. Our account manager supports both the developer and the client. She actively communicates with the employee and provides assistance when needed.',
  'Feedback facilitation. We actively engage in the feedback process by gathering comments from both parties. This feedback exchange helps to support the whole project.',
];
const ai = [
  'AI-generated location text. It accurately describes the surrounding area and offers an informative introduction.',
  "A table of points of interest (POI). It provides a clear overview of nearby points of interest, and the best part is that you can customize this table to suit your client's specific needs.",
  'A potpourri of the map rounds off the exposure and provides visual insights into the micro and macro location of the property.',
];
const features = [
  'Automatic generation of text descriptions for locations, appealing to potential buyers.',
  'Creation of promotional brochures for real estate properties.',
  'Providing combined descriptions of both the location and the property itself.',
  'Conversion of formal-style text into a more informal tone.',
  'Ability to ask the AI general questions on various topics of interest.',
];
const benefits = [
  'time savings,',
  'cost reductions,',
  'improved communication with tenants,',
  'increased transparency,',
  'enhanced data security.',
];

const AreaButler = () => {
  const data = useStaticQuery(graphql`
    query {
      timper: file(relativePath: { eq: "timper.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      laptop: file(relativePath: { eq: "areabutler-laptop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "areabutler-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "areabutler-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "areabutler-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iPhone: file(relativePath: { eq: "areabutler-iPhone.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "areabutler-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "areabutler-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "areabutler-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "areabutler-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "areabutler-screen-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "areabutler-screen-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "areabutler-screen-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "areabutler-screen-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "areabutler-screen-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen13: file(relativePath: { eq: "areabutler-screen-13.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen14: file(relativePath: { eq: "areabutler-screen-14.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen15: file(relativePath: { eq: "areabutler-screen-15.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen16: file(relativePath: { eq: "areabutler-screen-16.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen17: file(relativePath: { eq: "areabutler-screen-17.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen18: file(relativePath: { eq: "areabutler-screen-18.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen19: file(relativePath: { eq: "areabutler-screen-19.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen20: file(relativePath: { eq: "areabutler-screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lang: file(relativePath: { eq: "lang-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      isb: file(relativePath: { eq: "isb-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      karla: file(relativePath: { eq: "karla-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      reMax: file(relativePath: { eq: "re-max-logo.svg" }) {
        publicURL
      }
      immo: file(relativePath: { eq: "immo-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      video_cover: file(relativePath: { eq: "cover_areabutler.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const breakpoint = useBreakpoint();
  return (
    <Layout
      score={['Proptech', 'Germany', '2022', 'Ongoing']}
      navBtn="#D51762"
      layoutClassName={styles.main}
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Empowering Real Estate Software Solution with a Skilled Remote Team"
      subTitle="How Brocoders helped to augment a team for a German Proptech startup and supported the development of the SaaS solution for real estate sales and marketing with AI-based units."
      metaData={meta.areabutler}
    >
      <DemoVideo
        source="area_butler"
        poster={data.video_cover.childImageSharp.fluid.srcWebp}
        sourceForMobiles="area_butler_mini"
        hasWebm={false}
      />
      <div className={itemStyles.inner}>
        <h2 className={clsx(styles.title, styles.firstTitle)}>Our client</h2>
        <p className={itemStyles.paragraph}>
          <a
            href="https://areabutler.de/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            AreaButler
          </a>{' '}
          by KuDiBa GmbH is a German Proptech Startup that offer digital solutions for the real
          estate industry. Their SaaS solution is innovative for real estate sales and marketing.
          They help to find the right property and reach potential buyers through personalized
          location analysis and location presentation. Their target clients are brokers, real estate
          consultants, property managers, appraisers, building and real estate companies, and
          property owners. Alexander Timper, founder and CEO of{' '}
          <a
            href="https://areabutler.de/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            AreaButler
          </a>
          , is an innovation manager with an impressive track record in software development and
          optimization through innovations. With AreaButler, he set out to digitize the real estate
          industry to help every person find the best place to live. The goal is to help real estate
          agents perform their job successfully in a more efficient way.
        </p>
      </div>
      <Blockquote
        img={data.timper.childImageSharp.fluid}
        text="“After working together for over a year, Brocoders is fully part of our team. The service delivered is of very high quality. The execution is quick and includes very close communication. In case of bugs, the Brocoders team is super responsive and takes actions also late at night.”"
        link={{
          to: 'https://clutch.co/profile/brocoders#review-2006585',
          title: 'Read the full review on Clutch',
          style: styles.reviewLink,
          target: '_blank',
        }}
        blockquoteClassName={styles.blockquote}
        author={
          <React.Fragment>
            <strong>Alexander Timper </strong> <br />
            Founder &amp; CEO,{' '}
            <a
              href="https://area-butler.de/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              AreaButler
            </a>
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h3 className={styles.subTitle}>Business Idea</h3>
        <p className={itemStyles.paragraph}>
          AreaButler analyzes the property environment and provides a location presentation
          specifically for the target group you want to attract. Your client needs a luxury
          penthouse &#8212; only the best restaurants and supermarkets will be shown; need housing
          for a young family &#8212; kindergartens, schools, and pharmacies will be found. The
          solution provides strong arguments for a purchase or lease agreement, enhancing the real
          estate acquisition and sales process.
        </p>
        <h3 className={styles.subTitle}>Data Privacy</h3>
        <p className={itemStyles.paragraph}>
          Data Privacy is one of the company's key focuses. For AreaButler, data protection is a
          high priority, and they emphasize compliance with European data privacy regulations,
          particularly the General Data Protection Regulation (GDPR). The protection of confidential
          information is an additional factor inspiring customer trust.
        </p>
        <h2 className={styles.title}>The Situation</h2>
        <p className={itemStyles.paragraph}>
          {
            "By the time AreaButler contacted Brocoders, they were already in the process of developing geodata services for the real estate industry and had an MVP of their product. However, in order to drive further product development, the CEO of AreaButler needed a skilled full-stack developer to strengthen their team. Alexander Timper, the CEO, started searching for an IT company to enhance their software and develop additional features. \n\n They interviewed 20 companies worldwide to find suitable candidates to expand the internal team. In May 2022, AreaBuller contacted the CEO of Brocoders, and they started communicating. Alexander Timper found that Brocoders performed the best in the vendor selection process, and thus, our companies started cooperation. As a company specializing in augmenting tech startup teams, we started by providing one highly skilled full-stack developer to support the client's ongoing product development efforts."
          }
        </p>
      </div>
      <Blockquote
        img={data.timper.childImageSharp.fluid}
        text="“We work in an agile methodology, feature cards are developed based on customer and market feedback. Together with the Brocoders team, we estimate the efforts and prioritize based on customer benefit and market impact.”"
        link={{
          to: 'https://clutch.co/profile/brocoders#review-2006585',
          title: 'Read the full review on Clutch',
          style: styles.reviewLink,
          target: '_blank',
        }}
        blockquoteClassName={styles.blockquote}
        author={
          <React.Fragment>
            <strong>Alexander Timper </strong> <br />
            Founder &amp; CEO,{' '}
            <a
              href="https://area-butler.de/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              AreaButler
            </a>
          </React.Fragment>
        }
      />
      <BackgroundImage
        fluid={data.laptop.childImageSharp.fluid}
        className={clsx(styles.bg, styles.bg1)}
        style={{
          backgroundPosition: 'top left 62%',
        }}
      />
      <div className={clsx(itemStyles.inner, styles.workingProcess)}>
        <h2 className={styles.title}>Working Process</h2>
        <p className={itemStyles.paragraph}>
          To optimize collaboration for this project, we choose an outstaffing approach. It means
          that our developer joined the startup team and was managed by a client directly.
        </p>
        <ul className={styles.workingProcess_list}>
          {workingProcess.map(({ title, text }) => (
            <li className={styles.workingProcess_item} key={title}>
              <h4 className={styles.workingProcess_title}>{title}</h4>
              <p className={styles.workingProcess_paragraph}>{text}</p>
            </li>
          ))}
        </ul>
        <h3 className={styles.subTitle}>What did we do? Our Technologies</h3>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          Our developer consistently delivered top-notch services, such as:
        </p>
        <ul className={styles.bulletedList}>
          {technologies.map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className={itemStyles.paragraph}>The tech stack our developer utilized:</p>
        <ul className={styles.technologyList}>
          {technologyStack.map(({ field, list }) => (
            <li key={field}>
              <p className={styles.technologyTitle}>{field}</p>
              <Technology list={list} listStyle={styles.technologySubList} />
            </li>
          ))}
        </ul>
        <h2 className={clsx(styles.title, styles.teamMargin)}>Our Team</h2>
      </div>
      <Blockquote
        img={data.timper.childImageSharp.fluid}
        text="“The Brocoders team has fully adapted to our schedule and is always available.”"
        blockquoteClassName={styles.blockquote}
        author={
          <React.Fragment>
            <strong>Alexander Timper </strong> <br />
            Founder &amp; CEO,{' '}
            <a
              href="https://area-butler.de/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              AreaButler
            </a>
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          So, the Brocoders team included the following members:
        </p>
        <Team
          color="#C51245"
          list={[
            { count: 1, title: 'Full-stack Engineer\n & DevOps' },
            { count: 1, title: 'Account manager' },
          ]}
        />
        <p className={itemStyles.paragraph}>
          The developer has become an integral part of the team and sincerely contributes to the
          development of the product. They work closely with the client and establish effective
          communication and cooperation. The developer is actively involved in project planning and
          prioritization, taking into account both the client's expectations and the realistic ideas
          shared by the developer.
        </p>
        <p className={itemStyles.paragraph}>
          An account manager handles several key tasks for our developer, including labor cost
          planning, monthly workload management, working time tracking, invoice communication, and
          feedback facilitation.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          We do not just assign the developer to the client's team, but our account managers go
          above and beyond to provide comprehensive support throughout the process. This support
          includes:
        </p>
        <ul className={styles.bulletedList}>
          {onboarding.map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={itemStyles.inner}>
        <h2 className={styles.title}>Key Challenges</h2>
        <h3 className={styles.subTitle}>Integration to onOffice Marketplace</h3>

        <p className={itemStyles.paragraph}>
          <a
            href="https://www.marketplacedoc.onoffice.de/en/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            OnOffice Marketplace
          </a>
          {
            ' is a popular marketplace and leading provider of software for real estate agents in Germany, with over 35,000 users. The task was to integrate the AreaButler solution into the onOffice Marketplace. This solution would refresh the approach to property location search and increase the efficiency of players in the real estate market. Existing AreaButler users will benefit from a streamlined and automated data generation process to easily integrate custom AreaButler results into onOffice and their own websites. In addition, AreaButler extends support for agents that do not yet use onOffice by connecting them to various CRM systems.\n The challenge here for our developer was that the main technology stack of '
          }
          <a
            href="https://www.marketplacedoc.onoffice.de/en/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            OnOffice Marketplace
          </a>{' '}
          was different &#8212; PHP. Nevertheless, he found out how to overcome this discrepancy and
          completed the task. Since the main focus of the onOffice solution was on PHP support (with
          the limited one for JS), our developer had to implement the authentication process on the
          backend (for security reasons), taking into account the differences between some of the
          data types of these languages.
        </p>
        <h3 className={styles.subTitle}>Payment Integration</h3>
        <p className={itemStyles.paragraph}>
          Initially, the AreaButler solution used Stripe as its primary payment processor to accept
          payments. However, as the platform expanded, there arose a need to incorporate additional
          payment service providers such as PayPal. Our skilled developer fully investigated and
          integrated the functionalities of PayPal.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          The challenge centered on PayPal documentation, and our task was to handle PayPal
          integration with our client's unique pricing model. There are two types of the core
          AreaButler product:
        </p>
        <ul className={styles.bulletedList}>
          {[
            'In Subscription: when users can opt for a monthly or yearly subscription, which is automatically renewed unless you cancel it.',
            'Paper Use (based on consumption): this particular product offers the utilization of 10 interactive maps for 10 addresses within a one-year timeframe. It operates as a one-time purchase without renewal, separate from the subscription model.',
          ].map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className={itemStyles.paragraph}>
          However, we had to make sure that the trial period was both there and there for four days,
          according to our client's pricing model. Therein lay the challenge: the problem was that
          PayPal's functionality allowed the trial period to be applied only to subscriptions, not
          individual products.
        </p>
        <p className={itemStyles.paragraph}>
          Our developer did thorough research to find an effective solution and overcome this
          hurdle. After reviewing PayPal's internal payment documentation, a solution was found by
          implementing a single-term subscription, preventing any renewal possibility.
        </p>
        <h3 className={styles.subTitle}>Real Estate Data Import</h3>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          Real estate data integration is crucial as it eliminates the manual entry of information.
          Importing data about estate entities with their specific characteristics and various data
          formats becomes a real challenge. To enable seamless real estate data transfer, our
          full-stack developer worked out two options:
        </p>
        <ul className={styles.bulletedList}>
          <li className={styles.bulletedItem} key="CSV import ">
            <b>Importing real estate data in CSV format.</b>
            {
              '\nThe CSV import feature allows users to transfer data quickly and accurately instead of manual data entry, which is time-consuming and error-prone.'
            }
          </li>
          <li className={styles.bulletedItem} key="CRM">
            <b>Integration with CRM systems.</b>
            {
              '\nThe data can be imported into our system by connecting to external CRMs such as OnOffice Marketplace and Propstack.'
            }
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          One of the big challenges here was the different data formats and standards used on
          different platforms and systems. Integrating data from various sources, including CSV
          files and external CRMs, required careful consideration and conversion to ensure
          compatibility with the target system. Our developer overcome these challenges by
          identifying and comparing different data formats, reconciling differences in data field
          structures, and performing necessary transformations.
        </p>
        <h3 className={styles.subTitle}>AreaButler and GPT-3.5 Integration</h3>
        <p className={itemStyles.paragraph}>
          Another challenge from Alexander Timper was how to effectively use the power of AI to
          support real estate agents in their daily work. Brocoders developer has effectively
          integrated the powerful GPT-3.5-turbo from OpenAI into the AreaButler solution, providing
          one of the most capable models from the GPT-3.5 family to address the application needs.
        </p>
        <p className={itemStyles.paragraph}>
          This feature allows real estate agents to automatically generate high-quality location
          texts, property descriptions and whole texts. With this AI-powered capability, agents can
          save time and deliver stories that engage potential buyers and renters.
        </p>
        <Img fluid={data.screen1.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <p className={itemStyles.paragraph}>
          And for now, AreaButler is proud to be the first Proptech in the DACH marketplace (the
          German-speaking countries of Germany, Austria, and Switzerland) to make the GPT-3.5-turbo
          model available to their users.
        </p>
        <Img fluid={data.screen2.childImageSharp.fluid} className={styles.screenImg} alt="" />
      </div>

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered Solution</h2>
        <p className={itemStyles.paragraph}>
          The AreaButler solution is a Proptech SaaS product with AI features that provide
          personalized location analysis for real estate. For now, it is available in the German
          language. AreaButler works on laptops, PCs, tablets and smartphones. A mobile and desktop
          version of the product has been developed.
        </p>
        <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screenImg} alt="" />
      </div>
      <BackgroundImage fluid={data.iPhone.childImageSharp.fluid} className={styles.bg} />

      <div className={clsx(itemStyles.inner, styles.featuresPadding)}>
        <h2 className={styles.subTitle}>Main Features</h2>
        <h4 className={styles.workingProcess_title}>Interactive Maps</h4>

        <p className={itemStyles.paragraph}>
          Interactive maps are powerful tools for promoting property. With their help, you can give
          your potential buyers or tenants a complete visualization of the property's surrounding
          area. Interactive maps make your digital presentation more engaging and informative while
          keeping visitors on your site longer. You may form maps in your own design with full
          control over the content.
        </p>
        <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <h4 className={styles.workingProcess_title}>AI Location Exposure and AI Texts</h4>

        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          Automatically generated location exposures consist of three key elements:
        </p>
        <ul className={styles.bulletedList}>
          {ai.map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          We empowered the product with AI features based on GPT-3. AI can also use information
          stored in CRM systems to maximize the potential of internal resources. AreaButler
          leveraged the powerful advanced language model to offer the following features:
        </p>
        <ul className={styles.bulletedList}>
          {features.map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className={itemStyles.paragraph}>
          Through the application form, users enter their request parameters in order to analyze and
          submit a residential area or property. AI processes the request and provides the necessary
          information, including a detailed description of the location's infrastructure and the
          actual availability of the property.
        </p>
        <Img fluid={data.screen6.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <Img fluid={data.screen7.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <p className={itemStyles.paragraph}>
          As we mentioned, the client can ask the AI general theme questions. 
        </p>
        <Img fluid={data.screen8.childImageSharp.fluid} className={styles.screenImg} alt="" />

        <h4 className={styles.workingProcess_title}>Data-driven Environmental Analysis</h4>
        <p className={itemStyles.paragraph}>
          With the extensive AreaButler database, you have instant access to in-depth information:
          demographics, transportation links, educational information, proximity to recreational
          areas, and more. It is also a powerful marketing tool. With the help of the received
          information, you will be able to highlight the advantages of your property and present it
          in a favorable light.
        </p>
        <p className={itemStyles.paragraph}>
          We use government, our own and purchased data for our environmental analysis.
          Approximately 1.5 million data points are used for each analysis. Neighborhood
          demographics, air quality, election results, millions of points of interest, etc.
        </p>
        <h4 className={styles.workingProcess_title}>Location Plans</h4>
        <p className={itemStyles.paragraph}>
          AreaButler automatically generates print-ready location plans in over 300 DPI resolution,
          which you can easily download and integrate into your sales exposures. The service allows
          you to choose the map section and points of interest (POIs) that your target audience
          wants to see. Furthermore, you can customize the design of the maps to align with your
          corporate identity.
        </p>
        <h4 className={styles.workingProcess_title}>Location Indices</h4>
        <p className={itemStyles.paragraph}>
          AreaButler calculates eight different location indexes for you; each of them evaluates a
          specific aspect of your object's location. Each index has a value between 0 and 100 based
          on careful data analysis. Categories include aspects such as transportation, educational
          landscape, recreational opportunities, shopping, and more. This gives you a clear and
          comprehensive view of the attractiveness of your property.
        </p>
        <p className={itemStyles.paragraph}>
          Location indicators evaluate the living conditions that are performed in a particular
          area. By comparing with other properties, you can determine your position in the market
          and adjust your marketing strategy.
        </p>
        <Img fluid={data.screen9.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <h4 className={styles.workingProcess_title}>Customized design</h4>
        <p className={itemStyles.paragraph}>
          With AreaButler, you can not only present your property listings but also customize your
          maps to match your corporate style. You have the option to use your own colors and icons,
          reinforcing your brand. In addition, your own logo can be displayed on all export
          documents &#8212; another way to increase brand awareness. Fully customizable POI icons
          are also available to make your offer even more unique and attractive. You can select the
          color scheme, font, and even the icons for each POI category.
        </p>
        <h4 className={styles.workingProcess_title}>Integration with CRM, onOffice, Propstack</h4>
        <p className={itemStyles.paragraph}>
          AreaButler offers this feature directly in onOffice through their onOffice Marketplace
          App. If the client has a different or no CRM at all, then all services are also available
          through our web application. Also, we can use AreaButler with any other CRM synced through
          our API.
        </p>
        <h3 className={styles.subTitle}>Product general structure</h3>
        <h4 className={styles.workingProcess_title}>Environment Analysis</h4>
        <p className={itemStyles.paragraph}>
          Here you may put the needed address, set mobility and analysis radius, and choose
          localities (points of interest) and important addresses (for door-to-door routes in the
          map).
        </p>
        <Img fluid={data.screen10.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <Img fluid={data.screen11.childImageSharp.fluid} className={styles.screenImg} alt="" />

        <h4 className={styles.workingProcess_title}>My Real Estate</h4>
        <p className={itemStyles.paragraph}>
          Here you can create a new real estate object manually, select its type, put the address,
          price, and all property parameters, or import all this information from a CSV file.
        </p>
        <Img fluid={data.screen12.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <Img fluid={data.screen13.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <h4 className={styles.workingProcess_title}>My Target Groups</h4>
        <p className={itemStyles.paragraph}>
          You may choose the target group you need: active seniors, established performers, young
          professionals, (young) families, conservative/pragmatic middles, and students. You also
          might send an email to your prospect. This email contains a link that a potential client
          can use to indicate his preferred modes of transport and localities, as well as their
          important addresses.
        </p>
        <Img fluid={data.screen14.childImageSharp.fluid} className={styles.screenImg} alt="" />
        {/* <p className={itemStyles.paragraph}>Demographic data comparison.</p> */}
        <Img fluid={data.screen15.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <h4 className={styles.workingProcess_title}>My Maps</h4>
        <p className={itemStyles.paragraph}>
          Here you will find all your maps. Available actions: open editor, copy link, copy snippet,
          delete.
        </p>
        <Img fluid={data.screen16.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <p className={itemStyles.paragraph}>Demographic data comparison.</p>
        <Img fluid={data.screen17.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <p className={itemStyles.paragraph}>
          On the map, you can display the distribution of votes for a specific political party in
          elections in a particular area.
        </p>
        <Img fluid={data.screen18.childImageSharp.fluid} className={styles.screenImg} alt="" />
        <p className={itemStyles.paragraph}>You can limit the number of POIs.</p>
        <Img fluid={data.screen19.childImageSharp.fluid} className={styles.screenImg} alt="" />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The Results</h2>
        <p className={itemStyles.paragraph}>
          AreaButler has become a smart tool for a successful start in real estate. Over 100 brokers
          already rely on it. Now they use their maps to target specific audience groups and show
          exactly what buyers are interested in a residential area.
        </p>
        <p className={itemStyles.paragraph}>
          Thanks to interactive maps, visitors stay on the site for 39% longer. This greatly
          benefits your SEO ranking and increases your property's visibility in search engines.
        </p>
        <p className={itemStyles.paragraph}>
          Using AreaButler saves up to 86% of your time on searching and analyzing locations when
          preparing real estate offers. This solution does everything for real estate agents
          regarding location and creates real added value for property sales and marketing.
        </p>
        <h4 className={styles.benefitsTitle}>Benefits of AreaButler digital solution:</h4>
        <ul className={styles.bulletedList}>
          {benefits.map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <h3 className={styles.subTitle}>
          Some customers who already used AreaButler digital solution:
        </h3>
        <ul className={styles.iconList}>
          <li className={styles.iconItem} key="langimmobilien">
            <a href="https://www.langimmobilien.de" target="_blank" rel="noopener noreferrer">
              <img
                src={data.lang.childImageSharp.fluid.src}
                srcSet={data.lang.childImageSharp.fluid.srcWebp}
                className={clsx(styles.iconImg, styles.langImg)}
                alt=""
              />
            </a>
          </li>
          <li className={styles.iconItem} key="isb">
            <a
              href="https://www.isb-muenchen-immobilien.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={data.isb.childImageSharp.fluid.src}
                srcSet={data.isb.childImageSharp.fluid.srcWebp}
                className={clsx(styles.iconImg, styles.isbImg)}
                alt=""
              />
            </a>
          </li>
          <li className={styles.iconItem} key="karla-fricke">
            <a href="https://www.karla-fricke.de" target="_blank" rel="noopener noreferrer">
              <img
                src={data.karla.childImageSharp.fluid.src}
                srcSet={data.karla.childImageSharp.fluid.srcWebp}
                className={clsx(styles.iconImg, styles.karlaImg)}
                alt=""
              />
            </a>
          </li>
          <li className={styles.iconItem} key="remax">
            <a href="https://www.remax.eu/en" target="_blank" rel="noopener noreferrer">
              <img
                src={data.reMax.publicURL}
                className={clsx(styles.iconImg, styles.remaxImg)}
                alt="proto of adact apps"
              />
            </a>
          </li>
          <li className={styles.iconItem} key="immoaddict">
            <a href="https://www.immoaddict.de" target="_blank" rel="noopener noreferrer">
              <img
                src={data.immo.childImageSharp.fluid.src}
                srcSet={data.immo.childImageSharp.fluid.srcWebp}
                className={clsx(styles.iconImg, styles.immoImg)}
                alt=""
              />
            </a>
          </li>
        </ul>
        <h2 className={styles.cooperationTitle}>Further Cooperation&nbsp;— To Be Continued</h2>
        <p className={clsx(itemStyles.paragraph, styles.technologiesParagraph)}>
          As AreaButler continues its growth, they have plans for further development of the
          platform:
        </p>
        <ul className={styles.bulletedList}>
          {[
            'English version of the site;',
            'Admin panel;',
            "AreaButler's full functionality will soon be available for onOffice users. This includes processing property details, generating high-definition location plans, interactive iFrame maps, automated location exposures with AI-generated text, POI tables, and a QR code for easy engagement.",
          ].map((item) => (
            <li className={styles.bulletedItem} key={item}>
              {item}
            </li>
          ))}
        </ul>
        <p className={itemStyles.paragraph}>
          AreaButler and Brocoders have joined forces to develop an advanced model of AreaButler
          solution. We are creating a truly innovative product with exceptional features by
          combining AreaButler's expertise in data analytics, marketing, and technology with
          Brocoders' software development capabilities. This is really encouraging cooperation.
        </p>
      </div>

      <Blockquote
        img={data.timper.childImageSharp.fluid}
        text="“Words are kept, and problems are solved in a very pragmatic way. We can always rely on the support of the Team.”"
        blockquoteClassName={clsx(styles.blockquote, styles.lastBq)}
        author={
          <React.Fragment>
            <strong>Alexander Timper </strong> <br />
            Founder &amp; CEO,{' '}
            <a
              href="https://area-butler.de/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              AreaButler
            </a>
          </React.Fragment>
        }
      />
      {!breakpoint.s && (
        <BackgroundImage fluid={data.screen20.childImageSharp.fluid} className={styles.bg} />
      )}
    </Layout>
  );
};

export default AreaButler;
